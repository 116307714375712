import { gql, useMutation } from '@apollo/client';

import { VerifyUserMutation, VerifyUserMutationVariables } from '../gqlTypes';

const mutation = gql`
  mutation VerifyUser($code: String!) {
    verifyUser(code: $code) {
      success
      message
    }
  }
`;

export const useVerifyUser = () =>
  useMutation<VerifyUserMutation, VerifyUserMutationVariables>(mutation);
