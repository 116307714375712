import clsx, { ClassValue } from 'clsx';
import React from 'react';

type Props = {
  index: number;
  itemCount: number;
  className?: ClassValue;
  variant?: 'primary' | 'secondary';
};

export const PaginationDots: React.FC<Props> = (props) => {
  const variant = props.variant ?? 'primary';

  const dots: React.ReactNode[] = [];
  for (let i = 0; i < props.itemCount; i++) {
    dots.push(
      <div
        key={i}
        className={clsx(
          'rounded-full ml-1 w-2 h-2',
          props.index === i
            ? variant !== 'primary'
              ? 'bg-white'
              : 'bg-text-light-blue dark:bg-text-dark-blue'
            : variant !== 'primary'
            ? 'bg-blue-400'
            : 'bg-text-light-4 dark:bg-text-dark-4'
        )}
      />
    );
  }
  return (
    <div
      className={clsx('flex flex-row justify-center', props.className ?? '')}
    >
      {dots}
    </div>
  );
};
