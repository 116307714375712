import { gql, useMutation } from '@apollo/client';

import { CreateAccountMutation, CreateAccountMutationVariables } from '../gqlTypes';

const mutation = gql`
  mutation CreateAccount($input: CreateAccountInput!) {
    createAccount(input: $input) {
      success
      msg
    }
  }
`;

export const useCreateAccount = () =>
  useMutation<CreateAccountMutation, CreateAccountMutationVariables>(mutation);
